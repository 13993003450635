import { Card, Text } from '@aws-amplify/ui-react';

function WebPageFooter() {
    const style = {
        background: '#141F2E',
    };

    const textStyle = {
        color: '#FFFFFF',
    };

    return (
        <Card columnStart="1" columnEnd="-1" style={style}>
            <Text style={textStyle}>
                © 2024, Amazon Web Services, Inc. or its affiliates. All rights reserved.
            </Text>
        </Card>
    );
}

export default WebPageFooter;
