import React from 'react';
import { Card, Flex, Table, TableCell, TableBody, TableHead, TableRow, Text, Button } from '@aws-amplify/ui-react';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';

function WebPageNav({ items, onUploadComplete, onSearch, onItemSelect, hasMoreItems, onLoadMore, identityId, selectedItem, onDeleteItem }) {
    const style = {
        background: '#FFF',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    };

    const tableContainerStyle = {
        flex: 1,
        overflow: 'auto',
        marginTop: '1rem',
    };

    const selectedItemStyle = (isSelected) => ({
        cursor: 'pointer',
        backgroundColor: isSelected ? '#ed800b' : 'inherit',
        color: isSelected ? 'white' : 'inherit'
    });

    return (
        <Card columnStart="1" columnEnd="2" style={style}>
            <Flex direction="column" height="100%">
                <StorageManager
                    acceptedFileTypes={['.mov', '.mp3', '.wav', '.mp4', 'video/*', 'audio/*']}
                    path={`private/${identityId}/`}
                    maxFileCount={1}
                    onUploadSuccess={(result) => {
                        console.log('File uploaded successfully', result);
                        onUploadComplete();
                    }}
                    onUploadError={(error) => {
                        console.error('Error uploading file:', error);
                    }}
                />
                
                <input
                    type="text"
                    placeholder="Search files"
                    onChange={(e) => onSearch(e.target.value)}
                    style={{ padding: '8px', width: '100%', marginTop: '1rem' }}
                />
                
                <div style={tableContainerStyle}>
                    {items && items.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">File Path</TableCell>
                                    <TableCell as="th">Last Updated</TableCell>
                                    <TableCell as="th">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {items.map((item) => {
                                const fileName = item.object_name;
                                const isSelected = selectedItem && selectedItem.key === item.key;
                                return (
                                    <TableRow 
                                        key={fileName} 
                                        onClick={() => onItemSelect(item)}
                                        style={selectedItemStyle(isSelected)}
                                    >
                                        <TableCell>{fileName}</TableCell>
                                        <TableCell>{new Date(item.last_updated).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <Button onClick={(e) => { e.stopPropagation(); onDeleteItem(item); }}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    ) : (
                        <Text>No files available</Text>
                    )}
                </div>
                
                {hasMoreItems && (
                    <Button onClick={onLoadMore} style={{ marginTop: '1rem' }}>Load More</Button>
                )}
            </Flex>
        </Card>
    );
}

export default WebPageNav;
