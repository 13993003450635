/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listItems = /* GraphQL */ `
  query ListItems($limit: Int, $nextToken: String) {
    listItems(limit: $limit, nextToken: $nextToken) {
      items {
        cognito_identity_id
        object_name
        bucket
        key
        last_updated
        transcription_confidence
        transcription_job_name
        transcription_text
        technical_design_document
        meeting_summary
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchItems = /* GraphQL */ `
  query SearchItems($query: String!, $limit: Int, $nextToken: String) {
    searchItems(query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        cognito_identity_id
        object_name
        bucket
        key
        last_updated
        transcription_confidence
        transcription_job_name
        transcription_text
        meeting_summary
        technical_design_document
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($cognito_identity_id: String!, $object_name: String!) {
    getItem(cognito_identity_id: $cognito_identity_id, object_name: $object_name) {
      cognito_identity_id
      object_name
      bucket
      key
      last_updated
      transcription_confidence
      transcription_job_name
      transcription_text
      meeting_summary
      technical_design_document
      __typename
    }
  }
`;
