/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const modifyItem = /* GraphQL */ `
  mutation ModifyItem($key: String!, $title: String, $content: String) {
    modifyItem(key: $key, title: $title, content: $content) {
      cognito_identity_id
      object_name
      bucket
      key
      last_updated
      transcription_confidence
      transcription_job_name
      transcription_text
      meeting_summary
      __typename
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem($cognito_identity_id: String!, $object_name: String!, $bucket: String!, $key: String!) {
    deleteItem(cognito_identity_id: $cognito_identity_id, object_name: $object_name, bucket: $bucket, key: $key) {
      success
      __typename
    }
  }
`;
export const deleteItems = /* GraphQL */ `
  mutation DeleteItems($keys: [String!]!) {
    deleteItems(keys: $keys) {
      success
      __typename
    }
  }
`;
export const requestMeetingSummary = /* GraphQL */ `
  mutation RequestMeetingSummary($cognito_identity_id: String!, $object_name: String!) {
    requestMeetingSummary(cognito_identity_id: $cognito_identity_id, object_name: $object_name) {
      cognito_identity_id
      object_name
      bucket
      key
      last_updated
      transcription_confidence
      transcription_job_name
      transcription_text
      meeting_summary
      __typename
    }
  }
`;
