import React, { useState, useEffect, useCallback } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { configureAmplify } from "./services";
import { Grid } from '@aws-amplify/ui-react';
import WebPageHeader from './components/WebPageHeader';
import WebPageNav from './components/WebPageNav';
import WebPageMain from './components/WebPageMain';
import WebPageFooter from './components/WebPageFooter';
import { generateClient } from 'aws-amplify/api';
import { listItems, searchItems, getItem } from './graphql/queries';
import { deleteItem } from './graphql/mutations';
import { requestMeetingSummary } from './graphql/mutations';
import { onModifyItem, onDeleteItem, onRequestMeetingSummary } from './graphql/subscriptions';
import { fetchAuthSession } from 'aws-amplify/auth'

configureAmplify();

const client = generateClient();

function App() {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [nextToken, setNextToken] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [isSummaryGenerating, setIsSummaryGenerating] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [identityId, setIdentityId] = useState(null);
    
    const fetchItems = useCallback(async () => {
        try {
            setIsFetching(true);
            const query = searchTerm ? searchItems : listItems;
            const variables = { limit: 1000 };
            if (searchTerm) {
                variables.query = searchTerm;
            }
            const response = await client.graphql({
                query: query,
                variables: variables
            });
            const rawItems = response.data[searchTerm ? 'searchItems' : 'listItems'].items;
            // const validItems = rawItems.filter(item => {
            //     if (!item.bucket || !item.key) {
            //         console.error('Invalid item:', item);
            //         return false;
            //     }
            //     return true;
            // });
            setItems(rawItems);
            setNextToken(response.data[searchTerm ? 'searchItems' : 'listItems'].nextToken);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setIsFetching(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        // Fetch the identity ID when the component mounts
        const fetchIdentityId = async () => {
            try {
                const credentials = await fetchAuthSession();
                setIdentityId(credentials.identityId);
            } catch (error) {
                console.error('Error fetching identity ID:', error);
            }
        };
        fetchIdentityId();

        fetchItems();

        const intervalId = setInterval(() => {
            fetchItems();
        }, 10*1000);

        const subscriptions = [
            client.graphql({ query: onModifyItem }).subscribe({
                next: ({ data }) => {
                    console.log('onModifyItem:', data);
                    const modifiedItem = data.onModifyItem;
                    setItems(prevItems => {
                        const index = prevItems.findIndex(item => item.key === modifiedItem.key);
                        if (index !== -1) {
                            const newItems = [...prevItems];
                            newItems[index] = modifiedItem;
                            return newItems;
                        }
                        return [modifiedItem, ...prevItems];
                    });
                    if (selectedItem && selectedItem.key === modifiedItem.key) {
                        setSelectedItem(modifiedItem);
                    }
                },
                error: error => console.error(error)
            }),
            client.graphql({ query: onDeleteItem }).subscribe({
                next: ({ data }) => {
                    if (data.onDeleteItem.success) {
                        setItems(prevItems => prevItems.filter(item => item.key !== data.onDeleteItem.key));
                    }
                },
                error: error => console.error(error)
            }),
            // ... keep other subscriptions (onDeleteItems, onRequestMeetingSummary) ...
            client.graphql({ query: onRequestMeetingSummary }).subscribe({
                next: ({ data }) => {
                    console.log('Meeting summary requested:', data.onRequestMeetingSummary);
                    fetchItems();
                },
                error: error => console.error(error)
            }),
        ];

        return () => {
            clearInterval(intervalId);
            subscriptions.forEach(subscription => subscription.unsubscribe());
        };
    }, [fetchItems, selectedItem]);

    const handleItemSelect = (item) => {
        console.log('Selected item:', item);
        setSelectedItem(item);
    };

    const handleDeleteItem = async (item) => {
        if (!item.key || !item.bucket || !item.object_name) {
            console.error('Error: key, bucket, and object_name are required');
            return;
        }
        try {
            const response = await client.graphql({
                query: deleteItem,
                variables: {
                    cognito_identity_id: item.cognito_identity_id,
                    object_name: item.object_name,
                    bucket: item.bucket,
                    key: item.key
                }
            });
            if (response.data.deleteItem.success) {
                setItems(prevItems => prevItems.filter(i => i.key !== item.key));
            } else {
                console.error('Error deleting item');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleRequestMeetingSummary = async (item) => {
        if (!item.object_name || !item.cognito_identity_id) {
            console.error('Error: object_name and cognito_identity_id are required');
            return;
        }
        try {
            setIsSummaryGenerating(true);
            const response = await client.graphql({
                query: requestMeetingSummary,
                variables: { cognito_identity_id: item.cognito_identity_id, object_name: item.object_name }
            });
            console.log('Meeting summary requested:', response.data.requestMeetingSummary);
            // Start polling for updates
            pollForSummaryUpdate(item);
        } catch (error) {
            console.error('Error requesting meeting summary:', error);
            setIsSummaryGenerating(false);
        }
    };

    const pollForSummaryUpdate = async (item) => {
        const pollInterval = setInterval(async () => {
            try {
                const response = await client.graphql({
                    query: getItem,
                    variables: { cognito_identity_id: item.cognito_identity_id, object_name: item.object_name }
                });
                const updatedItem = response.data.getItem;
                if (updatedItem.meeting_summary) {
                    clearInterval(pollInterval);
                    setSelectedItem(updatedItem);
                    setIsSummaryGenerating(false);
                }
            } catch (error) {
                console.error('Error polling for summary update:', error);
                clearInterval(pollInterval);
                setIsSummaryGenerating(false);
            }
        }, 5000); // Poll every 5 seconds
    };

    const handleUploadComplete = useCallback(async () => {
        console.log('Upload completed, fetching updated items');
        await fetchItems();
    }, [fetchItems]);

    const handleSearch = async (term) => {
        setSearchTerm(term);
    };

    const loadMoreItems = () => {
        if (nextToken && !isFetching) {
            fetchItems();
        }
    };

    return (
        <Authenticator hideSignUp={true}>
            {({ signOut, user }) => (
                <Grid
                    templateColumns="1fr 3fr"
                    templateRows="auto 1fr auto"
                    gap="1rem"
                    height="100vh"
                >
                    <WebPageHeader user={user} onSignOut={signOut} />
                    <WebPageNav
                        items={items}
                        onUploadComplete={handleUploadComplete}
                        onSearch={handleSearch}
                        onItemSelect={handleItemSelect}
                        hasMoreItems={!!nextToken}
                        onLoadMore={loadMoreItems}
                        identityId={identityId}
                        selectedItem={selectedItem}
                        onDeleteItem={handleDeleteItem}
                    />
                    <WebPageMain
                        selectedItem={selectedItem}
                        onRequestMeetingSummary={handleRequestMeetingSummary}
                        isSummaryGenerating={isSummaryGenerating}
                    />
                    <WebPageFooter />
                </Grid>
            )}
        </Authenticator>
    );
}

export default App;
