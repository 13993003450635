/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onModifyItem = /* GraphQL */ `
  subscription OnModifyItem {
    onModifyItem {
      cognito_identity_id
      object_name
      bucket
      key
      last_updated
      transcription_confidence
      transcription_job_name
      transcription_text
      meeting_summary
      __typename
    }
  }
`;

export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem {
    onDeleteItem {
      success
      __typename
    }
  }
`;

export const onDeleteItems = /* GraphQL */ `
  subscription OnDeleteItems {
    onDeleteItems {
      success
      __typename
    }
  }
`;

export const onRequestMeetingSummary = /* GraphQL */ `
  subscription OnRequestMeetingSummary {
    onRequestMeetingSummary {
      cognito_identity_id
      object_name
      bucket
      key
      last_updated
      transcription_confidence
      transcription_job_name
      transcription_text
      meeting_summary
      __typename
    }
  }
`;
