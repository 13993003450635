import { Amplify } from 'aws-amplify';
import awsExports from "./aws-exports";

export function configureAmplify() {

    // Configure Amplify in index file or root file
    Amplify.configure({
        Auth: {
            Cognito: {
                //  Amazon Cognito User Pool ID
                userPoolId: awsExports.USER_POOL_ID,
                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
                // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                identityPoolId: awsExports.IDENTITY_POOL_ID
            }
        },
        Storage: {
            S3: {
                bucket: awsExports.USER_CONTENT_BUCKET,
                region: awsExports.AWS_REGION
            }
        },
        API: {
            GraphQL: {
                endpoint: awsExports.APPSYNC_GRAPHQL_ENDPOINT,
                region: awsExports.AWS_REGION,
                defaultAuthMode: 'userPool',
            }
        },
        DataStore: {
            authModeStrategyType: 'MULTI_AUTH'
        }
    });
}

//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level){
    Storage.configure({
        bucket: bucket,
        level: level,
        region: awsExports.AWS_REGION,
        identityPoolId: awsExports.IDENTITY_POOL_ID,
    });
}