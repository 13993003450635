import React, { useEffect } from 'react';
import { Tabs, Card, Text, Button } from '@aws-amplify/ui-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeStringify from "rehype-stringify";
import remarkMermaidPlugin from 'remark-mermaid-plugin';
import '@aws-amplify/ui-react/styles.css';
import mermaid from 'mermaid';

function WebPageMain({ selectedItem, onRequestMeetingSummary, isSummaryGenerating }) {
  useEffect(() => {
    mermaid.initialize({ startOnLoad: true });
  }, []);

  const cardStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // Prevent overflow from affecting parent
  };

  const tabsContainerStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // Prevent overflow from affecting parent
  };

  const tabPanelStyle = {
    flex: 1,
    overflow: 'auto', // Allow scrolling within the tab panel
    padding: '1rem',
  };

  const requestMeetingSummary = () => {
    if (selectedItem && selectedItem.object_name) {
      console.log('Requesting meeting summary for:', selectedItem.object_name);
      onRequestMeetingSummary(selectedItem).catch(error => {
        console.error('Error requesting meeting summary:', error);
      });
    } else {
      console.error('Cannot request meeting summary: selectedItem or key is missing');
    }
  };

  const downloadMarkdown = (content, type) => {
    const filename = `${selectedItem.object_name} - ${type}.md`;
    const element = document.createElement('a');
    const file = new Blob([content], {type: 'text/markdown'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Card style={cardStyle}>
      <Tabs.Container defaultValue="meeting-notes" style={tabsContainerStyle}>
        <Tabs.List>
          <Tabs.Item value="meeting-notes">Meeting Notes</Tabs.Item>
          <Tabs.Item value="tdd">Technical Design Document (TDD)</Tabs.Item>
          <Tabs.Item value="transcription">Transcription</Tabs.Item>
        </Tabs.List>
        <Tabs.Panel value="meeting-notes" style={tabPanelStyle}>
          {selectedItem && (
            <div>
              {isSummaryGenerating ? (
                <div>Generating meeting summary... Please wait.</div>
              ) : selectedItem.meeting_summary ? (
                <>
                  <ReactMarkdown
                    children={selectedItem.meeting_summary}
                    remarkPlugins={[remarkGfm]}
                  />
                  <Button onClick={() => downloadMarkdown(selectedItem.meeting_summary, 'meeting notes')}>
                    Download Meeting Summary
                  </Button>
                </>
              ) : (
                <>
                  <Text>No meeting summary available.</Text>
                  <Button onClick={requestMeetingSummary} disabled={isSummaryGenerating}>
                    {isSummaryGenerating ? 'Generating...' : 'Generate Meeting Summary'}
                  </Button>
                </>
              )}
            </div>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="tdd" style={tabPanelStyle}>
          {selectedItem && (
            <div>
              {selectedItem.technical_design_document ? (
                <>
                  <ReactMarkdown
                    children={selectedItem.technical_design_document}
                    remarkPlugins={[
                      remarkMermaidPlugin,
                      remarkGfm
                    ]}
                    rehypePlugins={[
                      rehypeRaw,
                      rehypeStringify,
                    ]}
                  />
                  <Button onClick={() => downloadMarkdown(selectedItem.technical_design_document, 'technical design document')}>
                    Download Technical Design Document
                  </Button>
                </>
              ) : (
                <div>
                  <Text>
                    This section will contain the Technical Design Document. You can add your TDD content here.
                  </Text>
                  <Button onClick={requestMeetingSummary}>
                    Generate Meeting Summary
                  </Button>
                </div>
              )}
            </div>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="transcription" style={tabPanelStyle}>
          {selectedItem && (
            <div>
              {selectedItem.transcription_text ? (
                <>
                  <ReactMarkdown
                    children={selectedItem.transcription_text}
                    remarkPlugins={[remarkGfm]}
                  />
                  <Button onClick={() => downloadMarkdown(selectedItem.transcription_text, 'transcription')}>
                    Download Transcription
                  </Button>
                </>
              ) : (
                <Text>No transcription available.</Text>
              )}
            </div>
          )}
        </Tabs.Panel>
      </Tabs.Container>
    </Card>
  );
}

export default WebPageMain;
