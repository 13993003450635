import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Flex, Card } from '@aws-amplify/ui-react';

import { fetchUserAttributes } from 'aws-amplify/auth';

function WebPageHeader({ onSignOut }) {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        async function fetchUserName() {
            try {
                const user = await fetchUserAttributes();
                setUserName(user.name);
            } catch (error) {
                console.log('Error fetching user name:', error);
            }
        }

        fetchUserName();
    }, []);

    const style = {
        background: '#141F2E',
    };

    return (
        <Card columnStart="1" columnEnd="-1" style={style}>
            <Flex
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                alignContent="flex-end"
                wrap="nowrap"
                gap="1rem"
            >
                <Menu
                    menuAlign="end"
                    size="small"
                >
                    <MenuItem onClick={() => alert('Show profile')}>
                        {userName} profile
                    </MenuItem>
                    <MenuItem onClick={onSignOut}>
                        Sign out
                    </MenuItem>
                </Menu>
            </Flex>
        </Card>
    );
}

export default WebPageHeader;
